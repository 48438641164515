import React from 'react';

//actions
import { useAction } from '../../../Actions/useAction';
import ModalActions from '../../../Actions/Modal';

//components
import Icon from '@/Styles/Icons/Icon';
import Modal from '../Modal';
import TranslationText from '../../TranslationText/TranslationText';

export default function FileErrorModal() {
  const modalActions = useAction(ModalActions);

  const closeModal = () => {
    modalActions.closeModal(true);
  };

  return (
    <Modal className='file-error-modal'>
      <div className='file-error-modal--close-icon' onClick={closeModal}>
        <Icon icon='close' />
      </div>
      <Modal.Panel position='center'>
        <Modal.Content>
          <Modal.Title>
            <Icon icon='alert-warning' />
            <TranslationText i18nKey='file-error'>File error</TranslationText>
          </Modal.Title>
          <div className='file-error-modal--info'>
            <TranslationText i18nKey='file-error-info'>
              An error occurred and your file is unable to load in Studio.
              Contact our support team to get help:
            </TranslationText>
          </div>
          <div className='file-error-modal--email'>
            <a href='mailto:support@shapertools.com'>{`support@shapertools.com`}</a>
          </div>
        </Modal.Content>
      </Modal.Panel>
    </Modal>
  );
}
