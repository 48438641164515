/* eslint-disable valid-jsdoc */
import { PayloadAction, UnknownAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import {
  setReconnectId,
  setSnapshot,
  setStatus,
  setWorkspace,
} from '../../Redux/Slices/SyncSlice';
import { open, create, disconnect } from './../SyncThunks';
import { log, logError, removeContextProperty } from './../SyncLog';
import { reconnectListener } from './ReconnectListener';
import { addSyncListener, context, SyncListenerApi } from '../SyncListener';
import { canvasInitialState, setCanvasState } from '@/Redux/Slices/CanvasSlice';
import { RootState } from '@/Redux/store';
import { DisconnectInterface } from '../SyncConstants';
import { resetViewport } from '@/Redux/Slices/ViewportSlice';
import { updateActiveSelection } from '@/Redux/Slices/SelectionSlice';
import UIModeAction from '@/Actions/UIMode';

/**
 * If we're not already disconnected and the disconnect action has been called,
 * perform the effect. Checks that the queue is empty and an update isn't
 * already sending a disconnect.
 *
 * Takes some optional params to determine if we should immediately create/open a new workspace
 * after successfully disconnecting or if we're simply disconnecting because the app has idled
 */
export const addDisconnectListener = (startListening: Function) => {
  startListening({
    predicate: (action: UnknownAction, currentState: RootState) => {
      const { status } = currentState.sync;
      return disconnect.match(action) && status !== 'disconnected';
    },
    effect: async (
      action: PayloadAction<Partial<DisconnectInterface> | null>,
      listenerApi: SyncListenerApi
    ) => {
      const { dispatch, getOriginalState, getState, cancel } = listenerApi;
      const originalStore = getOriginalState();
      const store = getState();
      const { mode } = originalStore.ui;
      const { workspace, enabled } = originalStore.sync;

      const { payload } = action;
      const idleApp = payload && 'idleApp' in payload ? payload.idleApp : false;

      if (!enabled) {
        dispatch(setStatus({ status: 'disconnected' }));
        if (idleApp) {
          dispatch(addSyncListener(reconnectListener));
        }
      }

      if (workspace) {
        const { id } = workspace;

        const workspaceId = JSON.parse(JSON.stringify(id));
        removeContextProperty('workspaceId');
        log(`Disconnecting workspace ${workspaceId}`, {
          ...context,
          workspace,
          action,
        });

        /**
         * A blocking call to ensure that there aren't any updates in the queue waiting to be
         * dispatched before disconnecting
         */
        const { sync } = store;
        const { queue, pendingQueue } = sync;
        if (queue.length > 0 || pendingQueue.length > 0) {
          log(
            `Waiting to disconnect workspace ${workspaceId}`,
            {
              ...context,
              queue: queue.length,
              pendingQueue: pendingQueue.length,
            },
            'debug'
          );
          cancel();
        }

        const createNewAfterDisconnect =
          payload && 'createNewWorkspaceAfterDisconnect' in payload
            ? payload.createNewWorkspaceAfterDisconnect
            : false;
        const openAfterDisconnect =
          payload && 'openWorkspaceAfterDisconnect' in payload
            ? payload.openWorkspaceAfterDisconnect
            : undefined;

        dispatch(setStatus({ status: 'disconnected' }));
        dispatch(setWorkspace(null));
        dispatch(setSnapshot({ value: null }));
        if (!idleApp) {
          dispatch(setCanvasState({ state: canvasInitialState }));
          dispatch(updateActiveSelection());
          dispatch(resetViewport());
        }

        if (payload) {
          // Going to create a new workspace after disconnect is successful
          if (createNewAfterDisconnect) {
            log(`Creating new workspace after disconnect`, { ...context });

            dispatch(create({}));

            // if we aren't in design mode, we should default back to it
            if (mode !== 'default') {
              const uiModeAction = new UIModeAction(dispatch, useSelector);
              uiModeAction.toDefault();
            }
          } else if (idleApp) {
            // Going to keep track of the previous workspace id so we can reconnect when we stop idling
            if (workspaceId) {
              log(
                `Idling app after disconnect, will reconnect to ${workspaceId}`,
                {
                  ...context,
                }
              );
              dispatch(setReconnectId(workspaceId));
              dispatch(addSyncListener(reconnectListener));
            } else {
              logError(
                `Unable to idle workspace because there is not reconnect id`,
                { ...context }
              );
            }
          } else if (
            // Going to open a workspace after disconnect is successful
            openAfterDisconnect &&
            openAfterDisconnect !== undefined
          ) {
            log(
              `Opening workspace ${openAfterDisconnect.workspaceId} after disconnect`,
              {
                ...context,
              }
            );
            dispatch(
              open({
                workspaceId: openAfterDisconnect.workspaceId,
                isDuplicate: openAfterDisconnect.isDuplicate,
              })
            );
          }
        }
      }
    },
  });
};
