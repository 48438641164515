import { createAction } from '@reduxjs/toolkit';

export const analyticsEventTypes = {
  IconPlaced: 'studio.icon-placed',
  ShapePlaced: 'studio.shape-placed',
  TextPlaced: 'studio.text-placed',
  PointPlaced: 'studio.point-placed',
  PolylinePlaced: 'studio.polyline-placed',
  UpdateText: 'studio.update-text',
  UpdateRoundedRectangle: 'studio.update-rounded_rectangle',
  ShapeShifterPlaced: 'studio.shapeshifter-placed',
  ShapeCopyPlaced: 'studio.shape-copy-placed',
  UnknownPlaced: 'studio.unknown-placed',
  SignIn: 'studio.sign-in',
  Start: 'studio.start',
  End: 'studio.end',
  AddContent: 'studio.add-content',
  SwitchMode: 'studio.switch-mode',
  OpenWorkspace: 'studio.open-workspace',
  EnableDemoMode: 'studio.enable-demo-mode',
  AccountCreateClick: 'studio.account-create-click',
  NewWorkspace: 'studio.new-workspace',
  WorkspaceOutOfDate: 'studio.workspace-out-of-date',
  IdleApp: 'studio.idle-app',
  Disconnect: 'studio.disconnect',
  Reconnect: 'studio.reconnect',
  OpenedProject: 'studio.opened-project',
  ConflictSequenceNumber: 'studio.conflict-sequence-number',
  ServerError: 'studio.server-error',
  SyncProjectToMyFiles: 'studio.sync-project-to-files',
  SwitchToReferencePath: 'studio.use-reference-path',
  ShareDesign: 'studio.share-design',
  DownloadSvg: 'studio.download-svg',
  Export: 'studio.export',
  ReviewModeTime: 'studio.review-mode-elapsed-time',
  ShapeShifterShapeCount: 'studio.shapeshifter-shape-count',
};

export const trackCreateAccountAction = createAction(
  'analytics/trackCreateAccount'
);
export const trackShareDesign = createAction('analytics/trackShareDesign');
export const trackExportWorkspace = createAction<'svg' | 'origin'>(
  'analytics/trackExportWorkspace'
);
export const trackReviewModeTime = createAction<number>(
  'analytics/trackReviewModeTime'
);
export const trackShapeShifterCount = createAction<number>(
  'analytics/trackShapeShifterCount'
);
