import { transform as basePathTransform } from '@/Geometry/BasePathOps';
import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';

import { Point } from '@shapertools/sherpa-svg-generator/Point';
import { Shape, SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';

import { TouchEvent, PointerEvent } from 'react';

export const CONTAINER_SIZE = 56;
export const CONTAINER_MID = CONTAINER_SIZE / 2;
export const DISPLAY_AREA = CONTAINER_SIZE * 0.66;

export type PendingShapePair = SvgGroup<Shape> & {
  pendingShapeSvg: string;
};

// checks for multi touch actions
export function isTouch(event: PointerEvent | TouchEvent): event is TouchEvent {
  return /^touch/.test(event.type);
}

// checks for multi touch actions
export function isMultiTouch(event: PointerEvent | TouchEvent) {
  return isTouch(event) && event.touches.length > 1;
}

export function getCoordinateFromEvent(event: PointerEvent | TouchEvent) {
  const source = isTouch(event) ? event.touches[0] : event;
  return { x: source.clientX, y: source.clientY };
}

// finds the id associated with fragment click event
export function getFragmentFromEvent(event: PointerEvent | TouchEvent) {
  const target = event.target;
  const source =
    target instanceof Element ? target.closest('[data-fragmentid]') : null;
  return source?.getAttribute('data-fragmentid') || '';
}

export function generateRawSVG(
  pendingShapePair: PendingShapePair,
  ratio: number,
  fragmentsCenterPos: Point
) {
  return `<svg width="${CONTAINER_SIZE}" height="${CONTAINER_SIZE}" >
                  <g transform="translate(${
                    CONTAINER_MID - ratio * fragmentsCenterPos.x
                  }, ${
    CONTAINER_MID - ratio * fragmentsCenterPos.y + 2.5
  }) scale(${ratio})" id="sg-${
    pendingShapePair.id
  }-root" vector-effect="non-scaling-stroke" >
                    ${pendingShapePair.pendingShapeSvg}
                  </g>
                </svg>`;
}

export function getTRSPathSet(svgGroup: SvgGroup) {
  const TRSPathSet: BasePath[] = [];
  const { TRSMtx } = svgGroup;
  let transformMtx = TRSMtx;

  for (const basePath of svgGroup.basePathSet) {
    if (basePath.outerPath) {
      TRSPathSet.push(
        new BasePath({
          points: basePath.outerPath.points,
          closed: basePath.outerPath.closed,
        })
      );
      if (basePath.holePaths) {
        TRSPathSet.push(
          ...basePath.holePaths.map(
            (p) => new BasePath({ points: p.points, closed: p.closed })
          )
        );
      }
    } else {
      TRSPathSet.push(
        new BasePath({
          points: basePath.points,
          closed: basePath.closed,
        })
      );
    }
  }

  //Finally, scale by TRSMtx, we defer this to the end so we don't have to scale path repair tolerances.
  return TRSPathSet.map((basePath) =>
    basePathTransform(basePath, transformMtx)
  );
}
