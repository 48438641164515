import {
  setUsername,
  loginShaperHub,
  getShaperSubscriptions,
  getExportAccess,
  getUser,
  selectProject,
  selectLocale,
} from '@/Redux/Slices/ShaperHubSlice';
import { selectFeaturesByMode } from '@/Redux/Slices/UISlice';
import * as Sentry from '@sentry/browser';
import {
  getFirstWorkspace,
  getStudioShare,
} from '../ShaperHub/ShaperHubThunks';
import { CustomErrors } from '../Constants/Errors';
import {
  selectSnapshot,
  selectWorkspaceIdFromUrlParam,
  setEnabled,
  setStatus,
} from '@/Redux/Slices/SyncSlice';
import { create, open } from '@/Sync/SyncThunks';
import InitializeAppAction from './InitializeApp';
import { entitlements } from '@/Helpers/Entitlements';
import { AppDispatch, RootState } from '@/Redux/store';
import { UseSelector } from './useAction';
import { setI18nLanguage } from '@/i18n';
import { setStudioPricesForUserFlow } from '@/Utility/studio-prices';

export default class LogInAction {
  dispatch: AppDispatch;
  useSelector: UseSelector;

  constructor(dispatch: AppDispatch, useSelector: UseSelector) {
    this.dispatch = dispatch;
    this.useSelector = useSelector;
  }

  logIn = async (username: string, password: string) => {
    const { dispatch, useSelector } = this;
    try {
      await dispatch(loginShaperHub({ u: username, p: password })).unwrap();
      dispatch(getUser());
      dispatch(getExportAccess());
      dispatch(setUsername(username));
      dispatch(getShaperSubscriptions());
      dispatch(getFirstWorkspace());

      const locale = this.useSelector((state) =>
        selectLocale(state as RootState)
      );

      setI18nLanguage(locale.language);
      setStudioPricesForUserFlow(locale);

      const useSync = this.useSelector((state) =>
        selectFeaturesByMode(state, entitlements.SYNC)
      );
      if (useSync) {
        dispatch(setEnabled(true));
        const project = useSelector(selectProject);
        if (project) {
          await dispatch(getStudioShare({ projectId: project._id }));
        } else {
          dispatch(setStatus({ status: 'disconnected' }));
          const workspaceIdFromUrlParam = useSelector(
            selectWorkspaceIdFromUrlParam
          );
          const snapshot = useSelector(selectSnapshot);
          if (workspaceIdFromUrlParam) {
            dispatch(open({ workspaceId: workspaceIdFromUrlParam }));
            return;
          }
          if (snapshot.canvas === null) {
            const initializeAppAction = new InitializeAppAction(
              dispatch,
              useSelector
            );
            const workspaceId =
              await initializeAppAction.getMostRecentActiveWorkspaceId();
            if (workspaceId) {
              dispatch(
                open({
                  workspaceId,
                })
              );
              return;
            }
          }
          dispatch(create({}));
        }
      }
    } catch (e: any) {
      //Http errors are not JS errors and sentry barks about this.
      const error =
        e.constructor.name !== 'Error' ? new Error(JSON.stringify(e)) : e;
      if (
        Sentry.isInitialized() &&
        Object.keys(error)?.length > 0 &&
        !Object.values(CustomErrors).includes(e.message)
      ) {
        Sentry.captureException(error);
      }

      // this will only work on localhost or staging environments
      if (e.message === CustomErrors.WHITELIST_IP) {
        return CustomErrors.WHITELIST_IP;
      }
      return true;
    }
  };
}
