/* eslint-disable valid-jsdoc */
import { setStatus } from '../../Redux/Slices/SyncSlice';
import { clearAlert, setAlert } from '@/Redux/Slices/UISlice';
import { ALERT_TYPES, MODAL_TYPES } from '@/defaults';
import { log } from './../SyncLog';
import { context, SyncListenerApi } from '../SyncListener';
import { PayloadAction } from '@reduxjs/toolkit';
import { SyncStatus } from '../SyncConstants';
import { setLoading } from '@/Redux/Slices/SherpaContainerSlice';
import * as Sentry from '@sentry/react';
import { SyncError } from '../SyncError';

export const addStatusListener = (startListening: Function) => {
  startListening({
    actionCreator: setStatus,
    effect: (
      action: PayloadAction<{ status: SyncStatus; error?: SyncError }>,
      { getState, dispatch, cancelActiveListeners }: SyncListenerApi
    ) => {
      // debounce
      cancelActiveListeners();

      const { payload } = action;
      const { status, error } = payload;
      const { sync, shaperHub } = getState();
      log(`Setting status: ${payload}`, { ...context, action }, 'debug');

      switch (status) {
        case 'edit':
          dispatch(setLoading(false));
          dispatch(clearAlert());
          break;

        case 'disconnected':
          dispatch(clearAlert());
          break;

        case 'out-of-date':
          dispatch(
            setAlert({
              msg: 'Design out of date',
              i18nKey: 'design-out-date',
              type: ALERT_TYPES.OUT_OF_SYNC,
              modal: MODAL_TYPES.SYNC_MODAL,
              className: 'out-of-sync-alert',
              position: 'top',
              modalIcon: 'question-mark-circle-inverse',
            })
          );
          break;
        case 'error':
          dispatch(setLoading(false));
          dispatch(
            setAlert({
              msg: 'Offline',
              i18nKey: 'offline',
              type: ALERT_TYPES.ERROR,
              icon: 'offline',
              modal: MODAL_TYPES.OFFLINE_MODAL,
            })
          );
          break;
        case 'invalid-workspace':
          dispatch(
            setAlert({
              msg: 'Cannot open workspace',
              i18nKey: 'fail-open',
              type: ALERT_TYPES.ERROR,
              icon: 'offline',
            })
          );
          break;
        case 'invalid-version':
          dispatch(
            setAlert({
              msg: 'Workspace Version is Not Compatible',
              type: 'error',
              icon: 'offline',
              modal: MODAL_TYPES.INVALID_MODAL,
            })
          );
          break;
        case 'bad-patches':
          dispatch(
            setAlert({
              msg: 'Cannot open workspace',
              i18nKey: 'fail-open',
              type: 'error',
              icon: 'offline',
            })
          );
          break;
        case 'bad-migration':
          const { user } = shaperHub;
          const { context: error_context } = error || {};
          const eventId = Sentry.captureMessage('Bad Migration', {
            tags: { key: 'migration' },
            extra: {
              error: error_context,
            },
          });

          Sentry.captureUserFeedback({
            name: user?._id || '',
            email: user?.email || '',
            comments: `Bad Migration - ${sync.workspace?.id}`,
            event_id: eventId,
          });

          dispatch(
            setAlert({
              msg: 'File error',
              i18nKey: 'file-error',
              type: 'error',
              icon: 'alert-warning',
              modal: MODAL_TYPES.FILE_ERROR_MODAL,
            })
          );
          break;
        default:
          break;
      }
    },
  });
};
