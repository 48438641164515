import { getSanitizedSvgGroup } from '@/Geometry/SvgGroupOps';
import { RootState } from './store';
import { UnknownAction } from '@reduxjs/toolkit';
import { setSnapshot } from './Slices/SyncSlice';
import {
  setCanvasFromSyncSnapshot,
  setCanvasState,
} from './Slices/CanvasSlice';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { doPatchValidation, update } from '@/Sync/SyncThunks';

const actionSanitizer = (action: UnknownAction): UnknownAction => {
  if (setSnapshot.match(action)) {
    const { type } = action;
    return {
      type,
    };
  } else if (setCanvasState.match(action)) {
    const { type, payload } = action;
    const { state } = payload;
    if (state && 'canvas' in state) {
      return {
        type,
        payload: {
          ...payload,
          canvas: {
            ...state.canvas,
            svgGroupSet: state.canvas.svgGroupSet.map((sg) =>
              getSanitizedSvgGroup(sg)
            ),
          },
        },
      };
    }
    return action;
  } else if (setCanvasFromSyncSnapshot.match(action)) {
    const { type } = action;
    return {
      type,
    };
  } else if (
    update.fulfilled.match(action) ||
    doPatchValidation.fulfilled.match(action)
  ) {
    const { payload, ...rest } = action;
    return rest;
  }
  return action;
};

const stateSanitizer = (state: RootState): RootState => {
  const sanitizedSvgGroupSet = state.canvas.canvas.svgGroupSet.map((sg) =>
    getSanitizedSvgGroup(sg)
  ) as SvgGroup[];

  return {
    ...state,
    canvas: {
      ...state.canvas,
      canvas: { ...state.canvas.canvas, svgGroupSet: sanitizedSvgGroupSet },
      undo: {
        past: state.canvas?.undo?.past?.map((p) => ({
          ...p,
          patches: p?.patches?.map((patch) => ({
            op: patch.op,
            path: patch.path,
          })),
          inversePatches: p?.inversePatches?.map((patch) => ({
            op: patch.op,
            path: patch.path,
          })),
        })),
        future: state.canvas?.undo?.future?.map((p) => ({
          ...p,
          patches: p?.patches?.map((patch) => ({
            op: patch.op,
            path: patch.path,
          })),
          inversePatches: p?.inversePatches?.map((patch) => ({
            op: patch.op,
            path: patch.path,
          })),
        })),
      },
    },
  };
};

export { stateSanitizer, actionSanitizer };
