import {
  AsyncThunk,
  createSlice,
  Middleware,
  PayloadAction,
} from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Fragment,
  FragmentList,
  FragmentListOps,
  FragmentSvg,
} from '../../ShapeShifter/ShapeShifterCore';
import { selectSelectedGroups } from '@/Redux/Slices/SelectionSlice';
import { PATH_TYPES } from '@shapertools/sherpa-svg-generator/PathTypes';
import { AABB } from '@shapertools/sherpa-svg-generator/AABB';
import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import { RootState } from '../store.js';
import { PATH_TYPE } from '@shapertools/sherpa-svg-generator/Path';
import {
  selectGetShapesForPoints,
  selectSelectedGroupsExcludingPoints,
} from './LineToolSlice.js';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { setLoading } from './SherpaContainerSlice';
import { generateFragmentsUnionSimplePolygons } from '@/Geometry/offset/Boolean';
import { selectTessellationFeatureFlag } from './FeatureFlagsSlice';
import { trackShapeShifterCount } from '@/Constants/Analytics';

interface FragmentSet {
  id: number;
  fragIds: string[];
  simplePolygons: BasePath[];
}

interface AddFragmentSetPayload {
  selectedFragmentIds: string[];
}

interface UpdateFragments {
  fragmentsSvgs: any;
  fragmentsAABB: AABB;
}

export const addSelectedGroupsToShapeShifter = createAsyncThunk(
  'shapeShifter/addSelectedGroupsToShapeShifter',
  async (_, { getState, dispatch }) => {
    // TODO: review this - probably incorrectly mutates state
    const store = getState() as RootState;

    // TODO: can remove once tessellation feature is complete
    const hasTessellationFeatureFlag = selectTessellationFeatureFlag(store);

    const getShapesForPoints = selectGetShapesForPoints(store);
    let selectedGroups = [...selectSelectedGroups(store)];
    const shapes = getShapesForPoints(selectedGroups);

    selectedGroups = [...selectedGroups, ...shapes];

    selectedGroups = selectedGroups.filter((g) =>
      g && g.type ? PATH_TYPES[g.type || PATH_TYPE.DESIGN].shapeshifter : true
    );

    // TODO: this is a temporary fix to exclude single line fonts from shapeshifter
    const groupsWithOutOpenText = selectedGroups.filter(
      (s: SvgGroup) =>
        !(
          s?.tool?.type === 'text-insert' &&
          s?.basePathSet!.some((b) =>
            b.outerPath ? !b.outerPath.closed : !b.closed
          )
        )
    );

    dispatch(trackShapeShifterCount(groupsWithOutOpenText.length || 0));

    return Promise.resolve(
      FragmentListOps.generateFragmentsFromGroups(
        FragmentListOps.createFragmentList(),
        groupsWithOutOpenText,
        hasTessellationFeatureFlag
      )
    );
  }
);

export const updateShapeShifterCanvas = createAsyncThunk(
  'shapeShifter/updateShapeShifterCanvas',
  async (params) => {
    return Promise.resolve(params);
  }
);

export const addFragmentSet = createAsyncThunk(
  'shapeShifter/addFragmentSet',
  async (params: AddFragmentSetPayload, { getState }) => {
    const store = getState() as RootState;
    const { shapeShifter } = store;

    // TODO: can remove once tessellation feature is complete
    const hasTessellationFeatureFlag = selectTessellationFeatureFlag(store);

    const fragments: Fragment[] =
      FragmentListOps.selectFragmentsFromFragmentIds(
        shapeShifter.fragmentList,
        params.selectedFragmentIds
      );

    const simplePolygons = await (async () => {
      if (hasTessellationFeatureFlag) {
        return generateFragmentsUnionSimplePolygons(
          fragments.map((f) => f.simplePolygon)
        );
      }
      return Promise.resolve(
        FragmentListOps.generateFragmentsUnionBasePathsArray(
          fragments.map((f) => f.simplePolygon)
        )
      );
    })();

    const nextFragmentSet: FragmentSet = {
      id: shapeShifter.nextFragmentSetId,
      fragIds: params.selectedFragmentIds,
      simplePolygons: simplePolygons,
    };
    return Promise.resolve(nextFragmentSet);
  }
);

export const clearFragmentSelections = createAsyncThunk(
  'shapeShifter/clearFragmentSelections',
  async (params) => {
    return Promise.resolve(params);
  }
);

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

export interface ShapeShifterSlice {
  status: 'idle' | 'pending';
  fragmentList: FragmentList;
  selectedGroupsFragmentsSvgs: FragmentSvg[];
  selectedGroupsFragmentsAABB: AABB;
  selectedFragmentSets: FragmentSet[];
  nextFragmentSetId: number;
}

export const initialState: ShapeShifterSlice = {
  status: 'idle',
  fragmentList: FragmentListOps.createFragmentList(),
  selectedGroupsFragmentsSvgs: [],
  selectedGroupsFragmentsAABB: new AABB(),
  selectedFragmentSets: [],
  nextFragmentSetId: 0,
};

//TODO - refactor slice to remove fragmentSet geometry. Fragments aren't used outside ShapeShifterContainer, so state hooks are more appropriate
//May be able to get rid of this entire slice
export const slice = createSlice({
  name: 'shapeShifter',
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It doesn't actually mutate the state because it uses the immer library, which detects changes to a "draft state" and produces a brand new immutable state based off those changes
    updateFragments: (state, action: PayloadAction<UpdateFragments>) => {
      const { fragmentsSvgs, fragmentsAABB } = action.payload;
      state.selectedGroupsFragmentsSvgs = fragmentsSvgs;
      state.selectedGroupsFragmentsAABB = fragmentsAABB;
    },
    clearShapeShifterFragments: (state) => {
      state.fragmentList = FragmentListOps.createFragmentList();
    },
    clearShapeShifter: (state) => {
      state.fragmentList = FragmentListOps.createFragmentList();
      state.selectedGroupsFragmentsSvgs = [];
      state.selectedGroupsFragmentsAABB = new AABB();
      state.selectedFragmentSets = [];
    },
    setFragmentSet: (state, action: PayloadAction<FragmentSet>) => {
      state.selectedFragmentSets = [
        ...state.selectedFragmentSets,
        action.payload,
      ];
      state.nextFragmentSetId++;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addSelectedGroupsToShapeShifter.fulfilled, (state, action) => {
        return {
          ...state,
          fragmentList: action.payload.fragmentList,
          status: 'idle',
        };
      })
      .addCase(clearFragmentSelections.fulfilled, (state) => {
        state.selectedFragmentSets = [];
        state.nextFragmentSetId = 0;
      })
      .addMatcher(
        // matcher can be defined inline as a type predicate function
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state) => {
          state.status = 'idle';
        }
      )
      .addMatcher(
        // matcher can be defined inline as a type predicate function
        (action): action is PendingAction => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'pending';
        }
      );
  },
});

export const middleware: Middleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (updateShapeShifterCanvas.fulfilled.match(action)) {
      const state = getState();
      const { shapeShifter } = state;
      const selectedGroupIds = selectSelectedGroupsExcludingPoints(state).map(
        (item) => item.id
      );

      if (selectedGroupIds.length > 0) {
        const { fragmentList } = shapeShifter;
        const selectedGroupsFragmentSvgs =
          FragmentListOps.generateFragmentsSvgs(fragmentList, selectedGroupIds);

        const selectedGroupsFragmentsAABB =
          FragmentListOps.generateFragmentsAABB(fragmentList, selectedGroupIds);

        dispatch(
          updateFragments({
            fragmentsSvgs: selectedGroupsFragmentSvgs,
            fragmentsAABB: selectedGroupsFragmentsAABB,
          })
        );
      } else {
        updateFragments({
          fragmentsSvgs: [],
          fragmentsAABB: new AABB(),
        });
      }
    } else if (clearShapeShifter.match(action)) {
      dispatch(setLoading(false));
    } else if (addFragmentSet.fulfilled.match(action)) {
      const nextFragmentSet = action.payload;
      dispatch(setFragmentSet(nextFragmentSet));
      dispatch(setLoading(false));
    }

    next(action);
  };

export const selectGroupsFragmentsSvgs = (state: RootState) => {
  return state.shapeShifter.selectedGroupsFragmentsSvgs;
};

export const selectGroupsFragmentsAABB = (state: RootState) => {
  return state.shapeShifter.selectedGroupsFragmentsAABB;
};

export const selectNextFragmentSetId = (state: RootState) => {
  return state.shapeShifter.nextFragmentSetId;
};

export const selectAllPendingShapesSimplePolygons = (state: RootState) => {
  return state.shapeShifter.selectedFragmentSets.map((fs) => fs.simplePolygons);
};

export const {
  clearShapeShifterFragments,
  clearShapeShifter,
  updateFragments,
  setFragmentSet,
} = slice.actions;
export default slice.reducer;
