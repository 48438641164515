import { cloneDeep } from 'lodash';
import { createSvgForTool, getShearedRectAABB } from '@/Helpers/ShapeCreator';
import { SHAPES_UPDATES_REQUIRED_WITH_BOUNDS_CHANGE } from '@/Constants/UI';
import { selectSvgGroupSet } from '@/Redux/Slices/CanvasSlice';
import { updateSvgGroup } from '@/CanvasContainer/CanvasActions';

import { createTextSvg } from '@/Helpers/TextCreator';
import UIState from '@/UILayer/State/UIState';
import {
  SvgGroupUpdate,
  SvgGroupUpdateKey,
  updateKeyAndValue,
} from '@/Geometry/SvgGroupOps';
import { UseSelector } from './useAction';
import {
  ISvgGroup,
  Shape,
  SvgGroup,
  Tool,
  ToolParams,
  isSvgGroupForShape,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import { Matrix33 } from '@shapertools/sherpa-svg-generator/Matrix33';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import { fontLibrary } from '@/Helpers/FontHelper';
import { AppDispatch } from '@/Redux/store';
import { selectTessellationFeatureFlag } from '@/Redux/Slices/FeatureFlagsSlice';
import { Bounds, getShapeBounds } from '@/LineTool/helpers';

type TextParamsSvgChange = {
  textParams: ToolParams<Shape.TEXT>;
};
type ToolParamsSvgChange = {
  toolParams: ToolParams & {
    deltaPos?: Point;
  };
};
type PositionSvgChange = {
  x: number;
  y: number;
};
type TranslateAndRotateSvgChange = {
  deltaPosition: Point;
  deltaRotation: number;
  setRotation: number;
} & (
  | {
      deltaRotation: number;
      setRotation: undefined;
    }
  | {
      deltaRotation: undefined;
      setRotation: number;
    }
);
type TranslateStretchSvgChange = {
  deltaPos: Point;
  stretchMtx: Matrix33;
};

export type SvgChange =
  | TextParamsSvgChange
  | ToolParamsSvgChange
  | PositionSvgChange
  | TranslateAndRotateSvgChange
  | TranslateStretchSvgChange;
export type SvgChangeSet = { [key: string]: SvgChange };

const hasTextParams = (params: SvgChange): params is TextParamsSvgChange =>
  ['textParams'].every((key) => key in params);
const hasToolParams = (params: SvgChange): params is ToolParamsSvgChange =>
  ['toolParams'].every((key) => key in params);
const hasPosition = (params: SvgChange): params is PositionSvgChange =>
  ['x', 'y'].every((key) => key in params);
const hasTranslateAndRotate = (
  params: SvgChange
): params is TranslateAndRotateSvgChange =>
  'deltaPosition' in params &&
  ['deltaRotation', 'setRotation'].some((key) => key in params);
const hasTranslateStretch = (
  params: SvgChange
): params is TranslateStretchSvgChange =>
  ['deltaPos', 'stretchMtx'].every((key) => key in params);
const hasRequiredBoundsChange = (
  params: SvgChange,
  group: SvgGroup
): params is TranslateStretchSvgChange =>
  hasTranslateStretch(params) &&
  SHAPES_UPDATES_REQUIRED_WITH_BOUNDS_CHANGE.includes(group.tool.type);

export default class ApplySvgChangeSetAction {
  dispatch: AppDispatch;
  useSelector: UseSelector;
  svgGroups?: SvgGroup[];

  constructor(dispatch: AppDispatch, useSelector: UseSelector) {
    this.dispatch = dispatch;
    this.useSelector = useSelector;
  }

  async resolve(changeSet: SvgChangeSet) {
    const { useSelector } = this;
    const svgGroups = useSelector(selectSvgGroupSet);

    // TODO: can remove once tessellation feature is complete
    const hasTessellationFeatureFlag = useSelector(
      selectTessellationFeatureFlag
    );
    const updates = [];

    // process each change
    const ids = Object.keys(changeSet || {});
    for (const id of ids) {
      const group = svgGroups.find((item) => item.id === id);
      if (!group) {
        continue;
      }
      const params = changeSet[id];

      // identify the change being made
      const update = ((): SvgGroupUpdate | null => {
        if (
          hasRequiredBoundsChange(params, group) ||
          hasTextParams(params) ||
          hasToolParams(params)
        ) {
          const regenerated = this._regeneratePath(
            group,
            group.tool.type,
            params
          );
          if (!regenerated) {
            return null;
          }
          return {
            key: SvgGroupUpdateKey.ToolSvg,
            value: {
              ...params,
              ...regenerated,
            },
          };
        } else if (hasPosition(params)) {
          return {
            key: SvgGroupUpdateKey.RelativePosition,
            value: new Point(params.x, params.y),
          };
        } else if (hasTranslateAndRotate(params)) {
          return {
            key: SvgGroupUpdateKey.RotationRelativePosition,
            value: params,
          };
        } else if (hasTranslateStretch(params)) {
          return {
            key: SvgGroupUpdateKey.RelativeStretchRelativePosition,
            value: params,
          };
        }

        return null;
      })();

      if (update === null) {
        console.error(
          `Change for ${id} did not generate a record in ApplySvgChangeSet`
        );
        continue;
      }

      updates.push({ id, update, hasTessellationFeatureFlag });
    }

    return updates;
  }

  async apply(changeSet: SvgChangeSet, { preview = false } = {}) {
    const { dispatch, useSelector } = this;
    const svgGroups = useSelector(selectSvgGroupSet);
    const updates = await this.resolve(changeSet);

    // TODO: can remove once tessellation feature is complete
    const hasTessellationFeatureFlag = useSelector(
      selectTessellationFeatureFlag
    );

    if (preview) {
      UIState.update({
        groups: updates.reduce((obj, update) => {
          const group = svgGroups.find((item) => item.id === update.id);
          const groupClone = cloneDeep(group);
          if (!groupClone) {
            return obj;
          }
          updateKeyAndValue(
            groupClone,
            update.update,
            hasTessellationFeatureFlag // TODO: can remove once tessellation feature is complete
          );
          return {
            ...obj,
            [update.id]: {
              data: groupClone,
            },
          };
        }, {}),
      }).apply();
    } else {
      // perform the update
      return dispatch(updateSvgGroup(updates));
    }
  }

  _regeneratePath(
    group: SvgGroup,
    type: Shape,
    change:
      | TextParamsSvgChange
      | ToolParamsSvgChange
      | TranslateStretchSvgChange
  ) {
    if (hasTextParams(change)) {
      if (isSvgGroupForShape(group, Shape.TEXT)) {
        return this._regenerateText(group, change);
      }
    } else {
      if (
        isSvgGroupForShape(group, Shape.ROUNDED_RECT) ||
        isSvgGroupForShape(group, Shape.SHAPE) ||
        isSvgGroupForShape(group, Shape.POLYGON)
      ) {
        return this._regenerateShape(group, change);
      }
    }

    console.error(`No path regeneration process found for ${type}`);
  }

  _regenerateText(
    group: SvgGroup<Shape.TEXT>,
    change: {
      textParams: ToolParams<Shape.TEXT>;
    }
  ) {
    const text = change.textParams.text || group.tool.params.text;
    const fontDisplayName =
      change.textParams.fontDisplayName || group.tool.params.fontDisplayName;
    const fontDisplayStyle =
      change.textParams.fontDisplayStyle || group.tool.params.fontDisplayStyle;
    const forceOpenPaths =
      change.textParams.fontDisplayStyle.forceOpenPaths || false;
    const font = fontLibrary.find((f) => f.fontDisplayName === fontDisplayName);
    if (!font) {
      throw new Error("Trying to regenerate text for font that doesn't exist");
    }
    const fontStyle = font.fontStyles.find(
      (fs) => fs.displayStyle === fontDisplayStyle.displayStyle
    );
    if (!fontStyle) {
      throw new Error(
        "Trying to regenerate text for font style that doesn't exist"
      );
    }

    const hasTessellationFeatureFlag = this.useSelector(
      selectTessellationFeatureFlag
    );
    return createTextSvg(
      text,
      fontDisplayName,
      fontStyle,
      forceOpenPaths,
      hasTessellationFeatureFlag // TODO: can remove once tessellation feature is complete
    );
  }

  _regenerateShape(
    group: SvgGroup,
    change: ToolParamsSvgChange | TranslateStretchSvgChange
  ) {
    const groups = this.svgGroups ?? this.useSelector(selectSvgGroupSet);
    const isShape = isSvgGroupForShape(group, Shape.SHAPE);
    const shapeData =
      isShape && getShapeBounds(group as ISvgGroup<Shape.SHAPE>, groups);

    const { params, stretchMtx, deltaPos } = (() => {
      if (hasToolParams(change)) {
        return {
          params: change.toolParams,
          stretchMtx: group.stretchMtx,
          deltaPos: change.toolParams.deltaPos || new Point(0, 0),
        };
      }

      return {
        params: group.tool.params,
        stretchMtx: change.stretchMtx,
        deltaPos: change.deltaPos,
      };
    })();

    const stretchedSize =
      group.tool.type === Shape.ROUNDED_RECT
        ? { x: params.width, y: params.height }
        : isShape
        ? { x: (shapeData as Bounds).width, y: (shapeData as Bounds).height }
        : getShearedRectAABB(params, stretchMtx, group.rotateMtx);

    const shapeParams = new Tool(group.tool.type, {
      ...params,
      width: stretchedSize.x,
      height: stretchedSize.y,
    });

    const generated = createSvgForTool(shapeParams);

    // generate the first result
    const result = {
      rawSVG: generated.svg,
      tool: shapeParams,
    };

    if (isShape) {
      return {
        ...result,
        setPosition: { ...shapeData } as Point,
      };
    }

    return {
      ...result,
      deltaPos,
    };
  }
}
