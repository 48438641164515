import { generateSvgPathData, getId, offsetSync } from '../BasePathOps';
import { getSvgPathCssClass, getToolOffset } from '../CutParamsOps';
import { performanceLogSync } from '../Helpers';
import clipper from '../offset/Clipper';
import {
  BasePath,
  generatePathId,
} from '@shapertools/sherpa-svg-generator/BasePath';
import { CutParams } from '@shapertools/sherpa-svg-generator/CutParams';
import math from '@shapertools/sherpa-svg-generator/mathjs';
import {
  createSvgPathFromStrsWithCSSAndAttributes,
  PathAttribute,
} from '@shapertools/sherpa-svg-generator/SvgGenerator';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { getScaledBasePathCache } from './BasePathCache';

export interface ToolPathSvgs {
  toolPathSvg: string;
  previewPathSvg: string;
}

const getToolPathSvgFromPathData = (
  svgGroupId: string,
  basePathId: string,
  cutParams: CutParams,
  pathData: string | []
) => {
  if (pathData === '') {
    return '';
  }
  const toolPathId = generatePathId(svgGroupId, basePathId, 'cutPreviewOnline');

  const toolPathCSS = getSvgPathCssClass(cutParams.cutType);

  return createSvgPathFromStrsWithCSSAndAttributes(
    pathData,
    toolPathId,
    toolPathCSS,
    []
  );
};

const getPreviewPathSvgFromPathData = (
  svgGroupId: string,
  basePathId: string,
  cutParams: CutParams,
  pathData: string
) => {
  if (pathData === '') {
    return '';
  }
  const previewPathId = generatePathId(
    svgGroupId,
    basePathId,
    'cutPreviewToolWidth'
  );

  const previewPathCSS = getSvgPathCssClass('toolWidth');

  const previewPathAttr: PathAttribute[] = [
    {
      name: 'stroke-width',
      value: math.unit(cutParams.toolDia).toNumber('mm').toString(),
    },
    { name: 'stroke-linejoin', value: 'round' },
    { name: 'stroke-linecap', value: 'round' },
  ]; //Never include cut offset, because width of cut is width of tool only. Cut offset only changes position of toolPath.

  return createSvgPathFromStrsWithCSSAndAttributes(
    pathData,
    previewPathId,
    previewPathCSS,
    previewPathAttr
  );
};

export const getToolPathSvgData = (
  scaledBasePath: BasePath,
  hasTessellationFeatureFlag: boolean
) => {
  const toolDiaOffset = getToolOffset(scaledBasePath.cutParams);
  const toolPaths = performanceLogSync<BasePath[]>(
    () =>
      hasTessellationFeatureFlag
        ? clipper.offsetSync(scaledBasePath, toolDiaOffset, false, true)
        : offsetSync(scaledBasePath, toolDiaOffset, false, true),
    `PERFORMANCE: getToolPathSvgData offset ${
      hasTessellationFeatureFlag ? 'Version 2' : ''
    } -> `
  );

  //BUT...
  //...toolpaths can be displayed as a single SVG path string, because they are styled and selected as a unit, so join this array into a single string
  return {
    pathSvgData: toolPaths
      .map((tp) => generateSvgPathData(tp, false))
      .join(' '),
    pathData: toolPaths,
  };
};

export const getToolPathCache = (
  basePath: BasePath,
  svgGroup: SvgGroup,
  extraArgs: { hasTessellationFeatureFlag: boolean }
): {
  pathSvg: ToolPathSvgs;
  pathData: BasePath[];
} => {
  const { hasTessellationFeatureFlag } = extraArgs;
  const basePathId = getId(basePath) as string;

  const renderedPath = basePath;
  const { pathData: allScaledBasePathData } = getScaledBasePathCache(
    renderedPath,
    svgGroup,
    { useSourceSvg: false, hasTessellationFeatureFlag }
  );
  const [scaledBasePathData] = allScaledBasePathData;

  const { pathSvgData, pathData } = getToolPathSvgData(
    scaledBasePathData,
    hasTessellationFeatureFlag
  );
  const toolPathSvg = getToolPathSvgFromPathData(
    svgGroup.id,
    basePathId,
    renderedPath.cutParams,
    pathSvgData
  );
  const previewPathSvg = getPreviewPathSvgFromPathData(
    svgGroup.id,
    basePathId,
    renderedPath.cutParams,
    pathSvgData
  );
  return { pathSvg: { toolPathSvg, previewPathSvg }, pathData };
};
