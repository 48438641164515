import classNames from 'classnames';
import { useSelector } from 'react-redux';

// helpers
import { isNumber } from 'lodash';
import { rotateAroundPoint } from '@/Utility/rotation';
import { isEqual } from '@/Utility/numeric';
import { radiansToDegreesFormattedNum } from '@/Geometry/UnitOps';

// selectors
import { selectToFormattedDisplayUnitValue } from '@/Redux/Slices/SherpaContainerSlice';
import { selectActiveAnchor } from '@/Redux/Slices/SelectionSlice';
import { selectNonScalingPixelFactor } from '@/Redux/Slices/ViewportSlice';
import { selectSelectedLine } from '@/Redux/Slices/LineToolSlice';

// components
import Label from './Label';
import SelectionBox from '../../Helpers/SelectionBoxHelper';
import { PATH_TYPES } from '@shapertools/sherpa-svg-generator/PathTypes';
import BoundingBoxCls from '../State/Helpers/BoundingBox';
import { Handle } from '@/@types/shaper-types';
import UIState from '../State/UIState';

// consts
const HORIZONTAL = 'horizontal';
const VERTICAL = 'vertical';
const ANGLE = 0;
const LINE = 1;

// sizing
// TODO: refactor
const DEFAULT_SIZE_LABEL_OFFSET = 32;
const boundingBoxLabelFontSize = 13.5;
const boundingBoxHandleSize = 5;
const boundingBoxHandleDistance = 3;
const boundingBoxRotationLabelOffset = 20;

type Props = {
  ui: UIState;
  hideLabels?: boolean;
  hideHandles?: boolean;
} & BoundingBoxCls;

export default function BoundingBox(props: Props) {
  const isSelectingAnchor = props.ui.uiMode === 'anchor-selection';
  const toFormattedDisplayUnitValue = useSelector(
    selectToFormattedDisplayUnitValue
  );
  const activeAnchor = useSelector(selectActiveAnchor);
  const nspf = useSelector(selectNonScalingPixelFactor);
  const hideLabels = 'hideLabels' in props;
  const isRotating = isNumber(props.ui.overrides?.rotate);
  const isTranslating = !!props.ui.overrides?.translate;
  const isResizing = !!props.ui.overrides?.resize;
  const rotatingAnchor = props.ui.overrides?.rotatingAnchor;

  // get bounding box data
  let {
    width,
    height,
    hw,
    hh,
    centroid,
    center,
    rotation = 0,
    origin,
    centroidRotationSvgTransform,
    mirrorX,
    mirrorY,
    ui,
  } = props;

  // normalize
  rotation %= Math.PI * 2;

  // gather some data
  const { x, y } = center;
  const handleSize = boundingBoxHandleSize * nspf;
  const handleOffset = boundingBoxHandleDistance * nspf;
  const rotationLabelOffset = boundingBoxRotationLabelOffset * nspf;
  const commonHandle = { rx: handleSize * 100, ry: handleSize * 100 };
  const anchoredHandle = { rx: handleSize * 0.25, ry: handleSize * 0.25 };
  const selectedLine = useSelector(selectSelectedLine);
  const { isSelectingSinglePoint } = ui;
  const selectedGroups = ui.selectedGroups;
  const hideHandles = selectedGroups.some(
    (sg) =>
      PATH_TYPES[sg.data.type as keyof typeof PATH_TYPES]?.selectability ===
      false
  );

  const anchor = ui.applyOverridesToPoint(ui.selectionBounds.anchor);
  const isAnchorSnappedToGuide =
    anchor &&
    props.ui.alignmentGuides?.find(
      (point) => isEqual(point.x, anchor.x, 3) || isEqual(point.y, anchor.y, 3)
    );

  // calculate the relative centroid position
  const cx = (centroid.x - x) / hw;
  const cy = (centroid.y - y) / hh;

  // when selecting a line, don't show
  if (selectedLine || isSelectingSinglePoint) {
    return null;
  }

  // find the actual displayed anchors
  const displayedHandles = SelectionBox.getDisplayedHandles(
    props,
    activeAnchor,
    nspf
  );

  function renderRotationLabels(tx: number, ty: number) {
    const transform = `translate(${tx + rotationLabelOffset}, ${
      ty - rotationLabelOffset
    })`;
    return (
      <g key='rotation-label-and-line' transform={transform}>
        <Label
          key={`label_rotation-label`}
          className={`bounding-box--label bounding-box--label--angle`}
          fontSize={boundingBoxLabelFontSize}
          radius={5}
          padding={5}
          text={`${radiansToDegreesFormattedNum(rotation.toString())}°`}
        />
      </g>
    );
  }

  function renderHandles() {
    const handles = (
      hideHandles || props.hideHandles
        ? []
        : [
            ['tl', -1, -1, ANGLE, 0],
            ['tr', 1, -1, ANGLE, 1],
            ['center', cx, cy, null, 0],
            ['bl', -1, 1, ANGLE, 3],
            ['br', 1, 1, ANGLE, 2],
            ['bm', 0, 1, LINE, 2],
            ['tm', 0, -1, LINE, 0],
            ['lm', -1, 0, LINE, 3],
            ['rm', 1, 0, LINE, 1],
          ]
    ) as [Handle, number, number, number | null, number][];
    return handles.map(
      ([handle, handleX, handleY, handleType, handleRotation]) => {
        const isActiveAnchor = handle === activeAnchor;

        // skip, if hidden
        if (
          !displayedHandles[handle] &&
          !isSelectingAnchor &&
          !isActiveAnchor
        ) {
          return undefined;
        }

        const radius = isActiveAnchor ? anchoredHandle : commonHandle;
        const size = handleSize;
        const tx = hw * handleX + handleOffset * handleX;
        const ty = hh * handleY + handleOffset * handleY;

        if (isNaN(tx) || isNaN(ty)) {
          return null;
        }

        const localRotation = 90 * handleRotation;
        const transform = `translate(${tx}, ${ty}) rotate(${localRotation})`;
        const anchorSize = size * 0.85;
        const anchorX = tx - anchorSize - anchorSize * handleX * 0.5;
        const anchorY = ty - anchorSize - anchorSize * handleY * 0.5;
        const isEdge = handleType === LINE;
        const isCorner = handleType === ANGLE;
        const isAnchorAligned = isActiveAnchor && isAnchorSnappedToGuide;
        const handleToShowRotationLabel = rotatingAnchor?.slice(
          rotatingAnchor.indexOf('_') + 1
        );
        const isRotatingHandle = handle === handleToShowRotationLabel;

        const handleCx = classNames('bounding-box--handle', {
          selected: handle === origin,
          active: isActiveAnchor,
          'is-aligned': isAnchorAligned,
        });

        const anchorCx = classNames('anchor-selector', {
          selected: isActiveAnchor,
        });

        // nothing to show
        if (
          props.hideBoundingBoxDetails &&
          (isEdge || isCorner) &&
          !isActiveAnchor
        ) {
          return null;
        }

        // create the handle
        let render;
        if (isActiveAnchor) {
          const hs = size * 0.85;
          render = (
            <rect x={-hs} width={hs * 2} y={-hs} height={hs * 2} {...radius} />
          );
        } else if (isEdge) {
          const offset = -(size + size * -0.5 + handleOffset);
          render = <line x1={-size} x2={size} y1={offset} y2={offset} />;
        } else if (isCorner) {
          render = (
            <path
              d={`M${-size} ${size} L${-size} ${-size} L ${size} ${-size}`}
            />
          );
        } else {
          const hs = size * 0.85;
          render = (
            <rect x={-hs} width={hs * 2} y={-hs} height={hs * 2} {...radius} />
          );
        }

        return (
          <g key={`handle_${handle}`}>
            <g
              transform={transform}
              className={handleCx}
              data-cy={`handle_${handle}`}
            >
              {render}
            </g>
            <rect
              x={anchorX}
              width={anchorSize * 2}
              y={anchorY}
              height={anchorSize * 2}
              className={anchorCx}
            />
            {isRotatingHandle && renderRotationLabels(tx, ty)}
          </g>
        );
      }
    );
  }

  function renderMeasurementLabels() {
    if (isRotating || (isTranslating && !isResizing)) {
      return null;
    }

    const offset = DEFAULT_SIZE_LABEL_OFFSET * nspf;

    // test all points
    let points = [
      [
        ...rotateAroundPoint(0, 0, width * 0.5 + offset, 0, -rotation),
        HORIZONTAL,
        Math.PI * 0.5,
      ],
      [
        ...rotateAroundPoint(0, 0, width * -0.5 - offset, 0, -rotation),
        HORIZONTAL,
        Math.PI * 0.5,
      ],
      [
        ...rotateAroundPoint(0, 0, 0, height * 0.5 + offset, -rotation),
        VERTICAL,
        0,
      ],
      [
        ...rotateAroundPoint(0, 0, 0, height * -0.5 - offset, -rotation),
        VERTICAL,
        0,
      ],
    ] as [number, number, string, number][];

    // find the horizontal axis to use
    points.sort((b, a) => b[0] - a[0]);
    const horizontalAxis = x < 0 ? points.shift()! : points.pop()!;
    points = points.filter((p) => p[2] !== horizontalAxis[2]);

    // find the vertical axis to use
    points.sort((b, a) => b[1] - a[1]);
    const verticalAxis = y < 0 ? points.shift()! : points.pop()!;

    return [horizontalAxis, verticalAxis].map(
      ([positionX, positionY, axis, localRotation], i) => {
        const value = axis === HORIZONTAL ? height : width;

        // not used at the moment
        const didChange = false;

        // normalize rotation
        let rotateTo = (localRotation + rotation) % Math.PI;
        if (rotateTo > Math.PI * 0.5) {
          rotateTo -= Math.PI;
        }

        return (
          <Label
            key={`label_${i}`}
            x={positionX}
            y={positionY}
            className={`bounding-box--label bounding-box--label--${axis} ${
              didChange && 'changed'
            } ${isRotating && 'rotating'}`}
            fontSize={boundingBoxLabelFontSize}
            rotation={rotateTo}
            radius={5}
            padding={5}
            text={toFormattedDisplayUnitValue(value, { includeUnit: true })}
          />
        );
      }
    );
  }

  const boundingBoxCx = classNames('bounding-box', {
    'disable-interactions': hideHandles && !props.hideHandles,
  });

  return (
    <g
      className={boundingBoxCx}
      transform={`translate(${x}, ${y})`}
      data-cy='bounding-box'
    >
      {!hideLabels && !hideHandles && renderMeasurementLabels()}

      <g transform={centroidRotationSvgTransform}>
        <rect
          id='selection-box'
          className='bounding-box--container'
          x={-hw * mirrorX}
          y={-hh * mirrorY}
          width={width}
          height={height}
          fill='none'
        />
        {renderHandles()}
      </g>
    </g>
  );
}
