import {
  applyPostProcess,
  createBasePathsAndDisplaySizeFromSvg,
} from '@/Geometry/SvgGroupOps';
import { createSvgForTool } from '@/Helpers/ShapeCreator';
import { createStretchMtx } from '@shapertools/sherpa-svg-generator/Matrix33';
import { PATH_TYPE } from '@shapertools/sherpa-svg-generator/Path';
import { Point, transform } from '@shapertools/sherpa-svg-generator/Point';
import {
  Shape,
  SvgGroup,
  Tool,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import * as Sentry from '@sentry/react';
import { isNegative } from 'mathjs';

export const migrations = [
  {
    from: '0.0',
    to: '1.0',
    up: (canvasSchema: any) => {
      const newSchema = {
        ...canvasSchema,
        version: '1.0',
      };
      return newSchema;
    },
    down: (canvasSchema: any) => {
      const { version, ...rest } = canvasSchema;
      return rest;
    },
  },
  {
    from: '1.0',
    to: '2.0',
    up: (canvasSchema: any) => {
      const newSchema = {
        version: '2.0',
        canvas: {
          canvas: {
            ...canvasSchema.canvas.canvas,
            svgGroupSet: canvasSchema.canvas.canvas.svgGroupSet.map(
              (s: any) => ({
                ...s,
                type: PATH_TYPE.DESIGN,
              })
            ),
          },
          version: '2.0',
        },
      };
      return newSchema;
    },
    down: (canvasSchema: any) => {
      const newSchema = {
        version: '1.0',
        canvas: {
          canvas: {
            ...canvasSchema.canvas.canvas,
            svgGroupSet: canvasSchema.canvas.canvas.svgGroupSet.map(
              (s: any) => {
                const { type, ...rest } = s;
                return {
                  ...rest,
                  basePathSet: s.basePathSet.map((b: any) => ({
                    ...b,
                    type: b.outerPath ? 'CutSimplePolygon' : 'CutPath',
                  })),
                };
              }
            ),
          },
          version: '1.0',
        },
      };
      return newSchema;
    },
  },
  {
    from: '2.0',
    to: '2.1',
    up: (canvasSchema: any) => {
      const newSchema = {
        canvas: {
          canvas: {
            ...canvasSchema.canvas.canvas,
            svgGroupSet: canvasSchema.canvas.canvas.svgGroupSet.map(
              (s: any) => {
                try {
                  if (s.postProcessPathSet && s.postProcessPathSet.length > 0) {
                    const { basePathSet, postProcessPathSet, ...rest } = s;

                    // create new rounded rectangle
                    const { params } = s.tool;
                    const newSize = transform(
                      new Point(params.width, params.height),
                      s.stretchMtx
                    );

                    // previously, we were using the stretch matrix for the width/height
                    // now we should actually leverage these params
                    const newRoundedRectTool = new Tool(Shape.ROUNDED_RECT, {
                      ...params,
                      width: Math.abs(newSize.x),
                      height: Math.abs(newSize.y),
                    });
                    const { svg: newRoundedRectSvg } =
                      createSvgForTool(newRoundedRectTool);
                    const { cutPaths, displaySize } =
                      createBasePathsAndDisplaySizeFromSvg(
                        true,
                        newRoundedRectSvg,
                        newRoundedRectTool
                      );

                    const newStretch = createStretchMtx(
                      isNegative(s.stretchMtx[0][0]) ? -1 : 1,
                      isNegative(s.stretchMtx[1][1]) ? -1 : 1,
                      0,
                      0
                    );

                    // recreate svg group in new format
                    const newSvgGroup = applyPostProcess(
                      new SvgGroup({
                        ...rest,
                        basePathSet: cutPaths,
                        displaySize: displaySize,
                        stretchMtx: newStretch,
                        tool: newRoundedRectTool,
                      })
                    );
                    return newSvgGroup;
                  }

                  // if shape is not a rounded rectangle, remove postProcessPathSet
                  // prop and that's it!
                  const { postProcessPathSet, ...rest } = s;
                  return rest;
                } catch (err) {
                  Sentry.captureException(err);
                }
                return s;
              }
            ),
          },
          version: '2.1',
        },
      };
      return newSchema;
    },
    down: (canvasSchema: any) => {
      const newSchema = {
        canvas: {
          canvas: {
            ...canvasSchema.canvas.canvas,
            svgGroupSet: canvasSchema.canvas.canvas.svgGroupSet.map(
              (s: any) => {
                if (s.tool.type === Shape.ROUNDED_RECT) {
                  return {
                    ...s,
                    postProcessPathSet: s.basePathSet,
                  };
                }
                return {
                  ...s,
                  postProcessPathSet: [],
                };
              }
            ),
          },
          version: '2.0',
        },
      };
      return newSchema;
    },
  },
];

export class MigrationError extends Error {
  fromVersion: string;
  toVersion: string;

  constructor(from: string, to: string) {
    const message = `Migration version ${from} -> ${to} not found`;
    super(message);
    this.name = 'Migration Error';
    this.fromVersion = from;
    this.toVersion = to;
    console.error(message);
  }
}

export class InvalidCanvasVersionError extends Error {
  constructor() {
    super(`Version is invalid`);
    this.name = 'Invalid Version Error';
  }
}
