/* eslint-disable valid-jsdoc */
import {
  selectSnapshot,
  setSnapshot,
  setStatus,
  updateQueue,
} from '../../Redux/Slices/SyncSlice';
import {
  applyPatches,
  immerToJSONPatches,
  createInitialPatch,
  createInitialPatchSet,
} from './../PatchGenerator';
import { setLoading } from '@/Redux/Slices/SherpaContainerSlice';
import { downloadBlob } from '@/ShaperHub/ShaperHubThunks';
import { log, logError, sanitizePatches } from './../SyncLog';
import { SyncListenerApi, context } from '../SyncListener';
import { useSelector } from '@/Actions/useAction';
import ImportGeometryAction from '@/Actions/ImportGeometry';
import ViewportActions from '@/Actions/Viewport';

/**
 * Adds an initial patch to a workspace either on create or if a workspace was
 * created without successfully applying an initial patch
 */
export const setupWorkspaceFromImport = async (
  listenerApi: SyncListenerApi,
  blobId: string
) => {
  const { dispatch, getState } = listenerApi;
  const { canvas, sync } = getState();
  const { queue } = sync;
  const initialPatch = createInitialPatch(canvas);
  const initialPatchSet = createInitialPatchSet(canvas);
  const snapshot = selectSnapshot();
  const jsonPatches = immerToJSONPatches(initialPatch);
  if (!jsonPatches) {
    logError('Unable to convert immer to json patches', {
      ...context,
      initialPatch: sanitizePatches(initialPatch),
    });
    return;
  }
  const newSnapshot = applyPatches(snapshot, jsonPatches);

  dispatch(setSnapshot(newSnapshot));

  const newQueue = [...queue, initialPatchSet];
  dispatch(updateQueue(newQueue));

  const downloadAction = await dispatch(
    downloadBlob({ blobId, skipImportScreen: true })
  );

  const importGeometryAction = new ImportGeometryAction(dispatch, useSelector);
  importGeometryAction.importSVG(downloadAction.payload, {
    skipImportScreen: true,
  });
  importGeometryAction.splitAndPlaceImportedGroup();

  log(
    `Created an initial import patch for workspace`,
    { ...context, initialPatch: sanitizePatches(initialPatch) },
    'debug'
  );

  setImmediate(() => {
    const viewportAction = new ViewportActions(dispatch, useSelector);
    viewportAction.centerTo();

    dispatch(setStatus({ status: 'edit' }));
    dispatch(setLoading(false));
  });
};
