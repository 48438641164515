import { setSnapshot, setStatus } from '../../Redux/Slices/SyncSlice';
import {
  doPatchValidation,
  update,
  ValidatePatchesParams,
} from './../SyncThunks';
import { applyPatches } from './../PatchGenerator';
import { log, sanitizePatches } from './../SyncLog';
import { SyncListenerApi, context } from '../SyncListener';
import { PayloadAction, UnknownAction } from '@reduxjs/toolkit';
import { rollbackQueue } from './RollbackListener';

export const validateSuccessListener = {
  predicate: (action: UnknownAction) => {
    return doPatchValidation.fulfilled.match(action);
  },
  effect: async (
    action: PayloadAction<ValidatePatchesParams>,
    { dispatch, unsubscribe }: SyncListenerApi
  ) => {
    const { jsonPatches, snapshot, patchesToSend } = action.payload;
    log('Sync is enabled, sending update for patches', {
      ...context,
      patchesToSend: sanitizePatches(patchesToSend),
    });

    const newSnapshot = applyPatches(snapshot, jsonPatches);
    dispatch(setSnapshot(newSnapshot));
    dispatch(update(patchesToSend));
    unsubscribe();
  },
};

export const validateFailListener = {
  predicate: (action: UnknownAction) => {
    return doPatchValidation.rejected.match(action);
  },
  effect: async (
    action: ReturnType<typeof doPatchValidation.rejected>,
    { dispatch, getState, unsubscribe }: SyncListenerApi
  ) => {
    const { snapshot } = action.meta.arg;
    dispatch(setStatus({ status: 'bad-patches' }));

    const { pendingQueue } = getState().sync;
    rollbackQueue(dispatch, pendingQueue, [], snapshot);
    unsubscribe();
  },
};

export const addValidateListener = (startListening: Function) => {
  startListening(validateSuccessListener);
  startListening(validateFailListener);
};
