import {
  analyticsEventTypes,
  trackCreateAccountAction,
  trackExportWorkspace,
  trackReviewModeTime,
  trackShapeShifterCount,
  trackShareDesign,
} from '../Constants/Analytics';
import posthog from 'posthog-js';
import { isAction, Middleware } from '@reduxjs/toolkit';
import {
  getExportAccess,
  getLoginState,
  getShaperSubscriptions,
  getStudioShare,
  getUser,
  loginShaperHub,
  logoutShaperHub,
  syncToMyFiles,
} from '@/ShaperHub/ShaperHubThunks';
import {
  addSVGGeometry,
  AddSVGGeometryPayload,
  updateSvgGroup,
  UpdateSvgGroupPayload,
} from '@/CanvasContainer/CanvasActions';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { disconnect, reconnect } from '@/Sync/SyncThunks';
import { setStatus } from '@/Redux/Slices/SyncSlice';
import { isArray } from 'lodash';
import { SvgGroupUpdateKey } from '@/Geometry/SvgGroupOps';
import { setUIMode } from '@/Redux/Slices/UISlice';
import * as Sentry from '@sentry/react';

const getContentParamsFromPayload = function (
  contentPayload: AddSVGGeometryPayload
) {
  if (contentPayload === undefined || contentPayload.tool === undefined) {
    return { type: analyticsEventTypes.UnknownPlaced };
  }
  const { type, params } = contentPayload.tool;

  switch (type) {
    case Shape.CIRCLE:
    case Shape.ELLIPSE:
    case Shape.RECTANGLE:
    case Shape.ROUNDED_RECT:
    case Shape.POLYGON:
      return {
        type: analyticsEventTypes.ShapePlaced,
        content_params: params,
      };

    case Shape.POINT:
      return {
        type: analyticsEventTypes.PointPlaced,
        content_params: params,
      };
    case Shape.SHAPE:
      return {
        type: analyticsEventTypes.PolylinePlaced,
        content_params: params,
      };

    case Shape.TEXT:
      //This is a font change event
      return {
        type: analyticsEventTypes.TextPlaced,
        content_params: params,
      };

    case Shape.ICON:
      return {
        type: analyticsEventTypes.IconPlaced,
        content_params: params,
      };

    case Shape.SHAPESHIFTER:
      return {
        type: analyticsEventTypes.ShapeShifterPlaced,
        content_params: params,
      };

    default:
      return { type: 'unknown', content_params: params };
  }
};

const sendUpdateText = (u: UpdateSvgGroupPayload) => {
  if (u.update.key === SvgGroupUpdateKey.ToolSvg) {
    if (u.update.value.tool.type === Shape.TEXT) {
      posthog.capture(analyticsEventTypes.UpdateText, {
        text_params: u.update.value.tool.params,
      });
    }
  }
};

//Middleware
export const analyticsMiddleware: Middleware = ({ getState }) => {
  return (next) => (action) => {
    const { sync, ui } = getState();
    if (isAction(action)) {
      try {
        if (getLoginState.fulfilled.match(action)) {
          posthog.capture(analyticsEventTypes.Start);
        }
        if (loginShaperHub.fulfilled.match(action)) {
          posthog.capture(analyticsEventTypes.SignIn);
        }
        if (getShaperSubscriptions.fulfilled.match(action)) {
          const { subscriptions } = action.payload;
          posthog.register_for_session({
            subscription_type: subscriptions.type,
          });
        }
        if (addSVGGeometry.fulfilled.match(action)) {
          const captureElement = getContentParamsFromPayload(action.payload);
          posthog.capture(captureElement.type, {
            content_params: captureElement.content_params,
          });
        }
        if (updateSvgGroup.fulfilled.match(action)) {
          const updatePayload = action.meta.arg;
          if (isArray(updatePayload)) {
            updatePayload.forEach((u) => {
              sendUpdateText(u);
            });
          } else {
            sendUpdateText(updatePayload);
          }
        }
        if (logoutShaperHub.fulfilled.match(action)) {
          posthog.capture(analyticsEventTypes.End);
        }
        if (getUser.fulfilled.match(action)) {
          posthog.register_for_session({
            has_origin_access: action.payload.hasOriginAccess,
          });
        }
        if (disconnect.match(action)) {
          if (action.payload) {
            const { idleApp } = action.payload;
            if (idleApp) {
              posthog.capture(analyticsEventTypes.IdleApp);
            }
          } else {
            posthog.capture(analyticsEventTypes.Disconnect);
          }
        }
        if (reconnect.match(action)) {
          const { reconnectId } = sync;
          posthog.capture(analyticsEventTypes.Reconnect, {
            reconnectId,
          });
        }
        if (setStatus.match(action)) {
          const { status } = action.payload;
          if (status === 'out-of-date') {
            posthog.capture(analyticsEventTypes.ConflictSequenceNumber);
          } else if (status === 'error') {
            posthog.capture(analyticsEventTypes.ServerError);
          }
        }
        if (getStudioShare.fulfilled.match(action)) {
          const { projectId } = action.meta.arg;
          posthog.capture(analyticsEventTypes.OpenedProject, { projectId });
        }
        if (syncToMyFiles.fulfilled.match(action)) {
          const projectId = action.meta.arg;
          posthog.capture(analyticsEventTypes.SyncProjectToMyFiles, {
            projectId,
          });
        }
        if (setUIMode.match(action)) {
          posthog.capture(analyticsEventTypes.SwitchMode, {
            mode: action.payload,
            from: ui.mode,
          });
        }
        if (trackCreateAccountAction.match(action)) {
          posthog.capture(analyticsEventTypes.AccountCreateClick);
        }
        if (trackShareDesign.match(action)) {
          posthog.capture(analyticsEventTypes.ShareDesign);
        }
        if (getExportAccess.fulfilled.match(action)) {
          const { hasUnlimitedExports, remainingExports } = action.payload;
          posthog.register_for_session({
            has_unlimited_exports: hasUnlimitedExports,
            remaining_exports: remainingExports,
          });
        }
        if (trackExportWorkspace.match(action)) {
          const exportType = action.payload;
          posthog.capture(analyticsEventTypes.Export, {
            exportType,
          });
        }
        if (trackReviewModeTime.match(action)) {
          const elapsedTime = action.payload;
          posthog.capture(analyticsEventTypes.ReviewModeTime, {
            elapsed_time: elapsedTime,
          });
        }
        if (trackShapeShifterCount.match(action)) {
          const numberOfShapes = action.payload;
          posthog.capture(analyticsEventTypes.ShapeShifterShapeCount, {
            number_of_shapeshifter_shapes: numberOfShapes,
          });
        }
      } catch (err) {
        Sentry.captureException(err);
      }
    }

    return next(action);
  };
};

export { analyticsMiddleware as default };
